<template>
  <v-sheet
    height="100%"
    color="grey lighten-3"
    class="overflow-hidden"
    style="position: relative;"
  >
    <v-btn
      color="white"
      fab
      absolute
      dark
      right
      small
      @click.stop="drawer = !drawer"
      class="mr-2 mt-12"
    >
      <v-icon color="primary">
        mdi-menu
      </v-icon>
    </v-btn>
    <router-view />
    <v-footer padless style="backgroundColor:transparent;">
      <v-col class="text-center details pa-1 " cols="12">
        粟海信息科技（苏州）有限公司
        <a
          class="text-decoration-underline "
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
        >
          苏ICP备17049731-2
        </a>
      </v-col>
    </v-footer>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-2">
              mdi-home-outline
            </v-icon>
            腹透远程系统
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
          <v-list-item-icon class="mr-3">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
export default {
  name: "WechatHome",
  data() {
    return {
      drawer: false,
      items: [
        {
          title: "腹膜透析(CAPD/APD)每日录入",
          icon: "mdi-view-dashboard-outline",
          to: "CAPDAPD",
        },
        {
          title: "自我管理能力",
          icon: "mdi-account-alert-outline",
          to: "SelfManagement",
        },
        { title: "生活质量", icon: "mdi-quality-medium", to: "QualityOfLife" },
        {
          title: "饮食依从行为",
          icon: "mdi-food-apple-outline",
          to: "EatingHabits",
        },
        {
          title: "上传报告",
          icon: "mdi-cloud-upload-outline",
          to: "UploadReport",
        },
        {
          title: "个人信息录入",
          icon: "mdi-card-account-details-outline",
          to: "UserInfo",
        },
        { title: "问题管理", icon: "mdi-help-circle-outline", to: "Questions" },
        { title: "医生关怀", icon: "mdi-doctor", to: "DoctorCare" },
        { title: "迈达历史记录", icon: "mdi-book-open", to: "History" },
        { title: "杰瑞历史记录", icon: "mdi-book-open", to: "HistoryJieRui" },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
.details {
  font-size: 14px;
  line-height: 42px;
  letter-spacing: 0.01rem;
}
</style>
